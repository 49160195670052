import React, { useState, useEffect, Suspense } from "react";
import clsx from "clsx";
import UseStyles from "./style";
import { useDispatch } from "react-redux";
import Navigaiton from "../../components/organisms/navigation/navigation";
import ProgressBar from "../../components/molecules/progressBar/progressBar";
import MenuIcon from "@material-ui/icons/Menu";
import Hidden from "@material-ui/core/Hidden";
import { setNavMenu } from "../../actions/navigationActionCreator";
import HeaderBar from "../../components/organisms/headerBar/headerBar";
import { ToastContainer } from "react-toastify";
// import AuthRoute from '../../components/auth/auth-routh';
import "react-toastify/dist/ReactToastify.css";
import AuthRoute from "../../components/auth/auth-routh";

const DashboardTemplate = ({ children }) => {
  const dispatch = useDispatch();
  const classes = UseStyles();
  const [openNav, setOpenNav] = useState(true);

  useEffect(() => {
    const menuItems = [
      "jobs",
      "candidate",
      // "Calendar & Task",
      "client",
      "user",
      "dashboard",
      "reports",
      "groups",
    ];
    dispatch(setNavMenu(menuItems));
  }, []);

  return (
    <AuthRoute>
      <ToastContainer
        position="top-center"
        autoClose={5000}
        closeOnClick
        style={{ zIndex: "10000" }}
      />
      <HeaderBar className={classes.headerBar}></HeaderBar>
      <Navigaiton
        className={classes.navigaitonBlock}
        openNav={true}
        setOpenNav={setOpenNav}
      />
      <div className={clsx(classes.infoBoardShift, classes.screen)}>
        <Hidden lgUp implementation="css">
          <MenuIcon
            className={classes.hamburger}
            onClick={() => setOpenNav(!openNav)}
          />
        </Hidden>
        <Suspense fallback={<ProgressBar />}>
          {children}
        </Suspense>
      </div>
    </AuthRoute>
  );
};

export default DashboardTemplate;
