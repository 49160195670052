import React, { useState, useEffect, useRef } from "react";
import clsx from "clsx";
import PropTypes from "prop-types";
import { useSelector, useDispatch } from "react-redux";
import { Link, useMatch, useParams } from "react-router-dom";
import MailIcon from "@material-ui/icons/Mail";
import Drawer from "@material-ui/core/Drawer";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import { Box } from "@material-ui/core";
import { clearState } from "../../../actions/authAction";
import { ListItemButton } from "@mui/material";

const NavDesktop = (props) => {
  const { classes, userMenu } = props;
  const { screen } = useParams();
  const dispatch = useDispatch();

  const handleLogout = () => {
    dispatch(clearState());
  };

  const convertToTitleCase = (str) => {
    return str
      .toLowerCase()
      .split(" ")
      .map(function (word) {
        return word.charAt(0).toUpperCase() + word.slice(1);
      })
      .join(" ");
  };

  return (
    <>
      <Drawer
        variant="permanent"
        className={clsx(
          classes.drawer,
          classes.drawerOpen
          // {
          //   [classes.drawerOpen]: Navigation.isOpen,
          //   [classes.drawerClose]: !Navigation.isOpen,
          // }
        )}
        // classes={{
        //   paper: clsx({
        //     [classes.drawerOpen]: Navigation.isOpen,
        //     [classes.drawerClose]: !Navigation.isOpen,
        //   }),
        // }}
        classes={{
          paper: classes.drawerOpen,
        }}
      >
        <Box className={classes.dividedSidebar}>
          <div className={classes.sidebarHeader}>Dashboard</div>
          <List className={classes.leftSpacing}>
            {userMenu.map((text, index) => (
              <Link
                to={
                  text !== "dashboard"
                    ? `/dashboard/${text.toLowerCase()}`
                    : "/dashboard/home"
                }
                key={index}
                className={classes.link}
              >
                <ListItemButton
                  className={classes.navButton}
                  selected={screen === text}
                >
                  <ListItemIcon>
                    <MailIcon className={classes.icon} />
                  </ListItemIcon>
                  <ListItemText
                    primary={convertToTitleCase(text)}
                    classes={{ primary: classes.navText }}
                    className={`${classes.nav} `}
                  />
                </ListItemButton>
              </Link>
            ))}
          </List>
        </Box>
      </Drawer>
    </>
  );
};
NavDesktop.propTypes = {
  classes: PropTypes.object.isRequired,
  userMenu: PropTypes.array.isRequired,
  handleDrawerOpen: PropTypes.func.isRequired,
  handleDrawerClose: PropTypes.func.isRequired,
};
export default NavDesktop;
