import React, { lazy } from "react";

const Home = lazy(() => import("./components/templates/home/home"));
const User = lazy(() => import("./components/templates/user/index"));
const Client = lazy(() => import("./components/templates/client/index"));
const Dashboard = lazy(() => import("./components/templates/dashboard/index"));
const JobsPage = lazy(() => import("./components/templates/job/index"));
const Reports = lazy(() => import("./components/templates/report/index"));
const Candidate = lazy(() => import("./components/templates/candidate/index"));
const Groups = lazy(() => import("./components/templates/groups/index"));

const Config = {
  visibleRecords: 10,
  modelMsgTimer: 5000,
  rejectedItems: [
    "Abusive/inappropriate Content",
    "Competitor Reference",
    "Customer Complaint",
    "Review applies to Different Entity",
    "Personally Identifiable Information",
    "Review is in a Foreign Language",
    "Others",
  ],
  startDate: new Date(
    new Date(Date.now() - 7 * 24 * 60 * 60 * 1000).setUTCHours(0, 0, 0)
  ).toISOString(),
  endDate: new Date(new Date(Date.now()).setUTCHours(23, 59, 59)).toISOString(),
  screens: {
    jobs: <JobsPage />,
    user: <User />,
    client: <Client />,
    dashboard: <Dashboard />,
    reports: <Reports />,
    addjob: <Home />,
    candidate: <Candidate />,
    groups: <Groups />,
  },
  roles: {
    user1: ["", "", ""],
    user2: [],
    user3: ["", "", ""],
  },
  auth: {
    domain: "hrtracker-dev.au.auth0.com",
    clientId: "WFKvY2PyGn2rHI8G9SWL4rqovkAE7Kit",
  },

  initialUserDataFormValue() {
    return {
      page: 1,
      size: Config.visibleRecords,
    };
  },
};

export default Config;
