import axios from "axios";

const host = `${process.env.REACT_APP_BACKEND_URL}/api`;

export const getTokenAndUser = async (token) => {
  try {
    let response = await axios.get(
      `${host}/auth/auth0/callback?access_token=${token}`
    );
    return response.data;
  } catch (error) {
    return error;
  }
};
