import { makeStyles } from "@material-ui/core/styles";

const drawerWidth = 260;
const useStyles = makeStyles((theme) => ({
  root: {},
  infoBoard: {
    //zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(["width", "padding"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    paddingLeft: 5,
    backgroundColor: "#F0F0F0",
    boxShadow: "none",
    position: "relative",
    paddingRight: 5,
    paddingTop: 5,
    paddingBottom: 10,
    zIndex: 9,
    [theme.breakpoints.up("lg")]: {
      paddingLeft: 80,
    },
  },
  infoBoardShift: {
    width: "auto",
    minWidth: "80%",
    backgroundColor: "#F1F4F5",
    transition: theme.transitions.create(["width", "padding"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    zIndex: 9,
    [theme.breakpoints.up("lg")]: {
      paddingLeft: drawerWidth + 30,
    },
  },
  navigaitonBlock: {
    zIndex: 99,
  },
  headerBar: {
    height: "66px",
  },
  screen: {
    height: "calc(100vh - 60px)",
    overflow: "auto",
  },
  hamburger: {
    padding: "0 10px ",
    display: "block",
    width: "45px",
    marginTop: "20px",
  },
  uploadBox: {
    marginTop: "16px",
  },
}));

export default useStyles;
