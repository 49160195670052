import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  headerBar: {
    background: "#FFF",
    paddingRight: "20px",
    display: "flex",
    alignItems: "center",
    position: "sticky",
    top: "0",
    height: "60px",
    marginLeft: "260px",
    boxShadow: "0px 2px 2px #0000000A",
    zIndex: 99,
  },
  logoContainer: {
    width: "365px",
  },
  rightMenu: {
    marginLeft: "auto",
    display: "flex",
    alignItems: "center",
    gap: "40px",

    "& a": {
      textDecoration: "none",
    },
  },
  searchBar: {
    display: "flex",
    alignItems: "center",
    "& .menu-icon": {
      padding: "0.75rem 1.5rem",
    },
    "& .MuiOutlinedInput-root": {
      borderRadius: "0px",
      borderRight: "1px solid #CCCCCC",
      borderLeft: "1px solid #CCCCCC",
    },
    "& input": {
      width: "100%",
      maxWidth: "260px",
      background: "#fff",
      minWidth: "248px",
      borderRadius: "0px",
      color: "#666666",
    },
    "& .searchIcon": {
      color: "#CCCCCC",
    },
    "& .MuiOutlinedInput-notchedOutline": {
      border: "0",
    },
  },
  clearBtn: {
    width: "100%",
    padding: "6px 15px 6px 10px",
    borderRadius: "20px",
    border: "1px solid #fff",
    height: "40px",
    marginLeft: "0px",
    fontSize: "16px",
    fontWeight: "normal",
    textTransform: "capitalize",
    color: "#bcbced",
    "& span": {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      "& .MuiTouchRipple-root": {
        display: "none !important",
      },
    },
  },
  clearBtnArrow: {
    transform: "rotate(90deg)",
  },
  clearLastBtn: {
    width: "100px",
    marginLeft: "15px",
    "& span": {
      justifyContent: "center",
      color: "#bcbced",
      fontSize: "15px",
    },
  },
  labelField: {
    marginLeft: "20px",
    color: "#bcbced",
    fontSize: "12px",
  },
  dateRangeText: {
    fontSize: "11px",
    paddingTop: "4px",
  },

  jobBtn: {
    border: "2px solid #4A90E2",
    borderRadius: "10px",
    color: "#4A90E2",
    height: "38px",
    display: "flex",
    alignItems: "center",
    fontSize: "14px",
    padding: "0 24px",
    userSelect: "none",

    "&:hover": {
      cursor: "pointer",
    },
  },
  avatar: {
    cursor: "pointer",
  },
  notificationIcon: {
    color: "#CCCCCC",
  },
}));

export default useStyles;
