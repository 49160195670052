import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  FormControl,
  IconButton,
  InputAdornment,
  InputLabel,
  Select,
  // TextField,
} from "@material-ui/core";
import TextField from "@mui/material/TextField";
import SearchIcon from "@mui/icons-material/Search";
import MenuIcon from "@mui/icons-material/Menu";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import { useDispatch, useSelector } from "react-redux";
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import BasicDateRangePicker from "../../molecules/dateRangePicker/dateRangeSelector";
import { paginationClasses } from "@mui/material";
import { useParams } from "react-router-dom";

const FilterBox = ({ classes }) => {
  const [search, setSearch] = useState("");

  return (
    <Box className={classes.searchBar}>
      {/* <TextField
          id="search-bar"
          label="Candidate"
          variant="outlined"
          onChange={(e) => setSearch(e.target.value)}
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              console.log(search);
            }
          }}
          placeholder="placeholder"
          value={search}
          size="small"
        /> */}
      <div className="menu-icon">
        <MenuIcon />
      </div>
      {/* <TextField
        id="search-bar"
        variant="outlined"
        onChange={(e) => setSearch(e.target.value)}
        onKeyDown={(e) => {
          if (e.key === "Enter") {
            console.log(search);
          }
        }}
        value={search}
        defaultValue="Search Candidate"
        placeholder="Search Candidate"
        InputLabelProps={{ shrink: true }}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end" onClick={() => console.log(search)}>
              <SearchIcon className="searchIcon" />
            </InputAdornment>
          ),
        }}
      /> */}
    </Box>
  );
};

export default FilterBox;
