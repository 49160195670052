import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

const Redirect = () => {
  const navigate = useNavigate();

  const redirect = async () => {
    await new Promise(resolve => setTimeout(resolve, 50));
    navigate("/dashboard/jobs");
  }
  
  useEffect(() => {
    redirect();
  }, []);

  return <span></span>;
}

export default Redirect;
