import { useEffect, useState } from "react";

import { useAuth0 } from "@auth0/auth0-react";
import { getTokenAndUser } from "../../services/auth.service";
import { getUserOwnedProfile } from "../../services/api/user-profile.service";
import { useNavigate } from "react-router-dom";

const AuthRoute = ({ children }) => {
  const {
    isLoading,
    isAuthenticated,
    loginWithRedirect,
    getAccessTokenSilently,
  } = useAuth0();

  const navigate = useNavigate();
  const [tokenFetched, setTokenFetched] = useState(false);

  const login = async () => {
    if (!isAuthenticated && !isLoading) {
      await loginWithRedirect();

    } else {
      const accessToken = await getAccessTokenSilently();
      const appToken = await getTokenAndUser(accessToken);
      localStorage.setItem("token", appToken.jwt);

      const userProfiles = await getUserOwnedProfile();
      const userRoles = userProfiles.map((profile) => profile.userRole);
      if (userRoles.length === 0) {
        navigate("/404");
      }

      setTokenFetched(true);
    }
  }

  useEffect(() => {
    login();
  }, [isLoading, isAuthenticated]);

  return <>{tokenFetched && isAuthenticated && children}</>;
};

export default AuthRoute;
