import React from "react";
import {
  Routes,
  Route,
  Navigate
} from "react-router-dom";
import AccessRequest from "../pages/404/404";
import Config from "../config";
import DashboardTemplate from "../pages/dashboard/dashboard-template";
import Redirect from "../pages/auth/redirect";

const CustomRoutes = () => {
  return (
    <Routes>
      <Route exact path="/" element={<Redirect />} />
      <Route exact path="/dashboard/jobs" element={<DashboardTemplate>{Config.screens["jobs"]}</DashboardTemplate>} />
      <Route exact path="/dashboard/candidate" element={<DashboardTemplate>{Config.screens["candidate"]}</DashboardTemplate>} />
      <Route exact path="/dashboard/client" element={<DashboardTemplate>{Config.screens["client"]}</DashboardTemplate>} />
      <Route exact path="/dashboard/user" element={<DashboardTemplate>{Config.screens["user"]}</DashboardTemplate>} />
      <Route exact path="/dashboard/home" element={<DashboardTemplate>{Config.screens["dashboard"]}</DashboardTemplate>} />
      <Route exact path="/dashboard/reports" element={<DashboardTemplate>{Config.screens["reports"]}</DashboardTemplate>} />
      <Route exact path="/dashboard/groups" element={<DashboardTemplate>{Config.screens["groups"]}</DashboardTemplate>} />
      <Route exact path="/dashboard/addjob" element={<DashboardTemplate>{Config.screens["addjob"]}</DashboardTemplate>} />
      <Route exact path="/jobs/:jobId/edit" element={<DashboardTemplate>{Config.screens["addjob"]}</DashboardTemplate>} />
      <Route exact path={"/404"} element={<AccessRequest />} />
      <Route path={"/404/:screen"} element={<AccessRequest />} />
    </Routes>
  );
};

export default CustomRoutes;
