import React from "react";
import { useDispatch } from "react-redux";
import UseStyles from "./style";
import { clearState } from "../../../actions/authAction";
import { Link, Navigate, useParams } from "react-router-dom";
import FilterBox from "./FilterBox";
import { Avatar } from "@material-ui/core";
import NotificationsNoneIcon from "@mui/icons-material/NotificationsNone";
import { useAuth0 } from "@auth0/auth0-react";

const HeaderBar = (props) => {
  const classes = UseStyles();
  const dispatch = useDispatch();
  const { screen } = useParams();
  const { logout, user } = useAuth0();

  const handleLogout = () => {
    dispatch(clearState());
  };

  return (
    <>
      <div className={classes.headerBar}>
        <FilterBox classes={classes} />
        <div className={classes.rightMenu}>
          <NotificationsNoneIcon
            className={classes.notificationIcon}
          ></NotificationsNoneIcon>
          <Avatar
            className={classes.avatar}
            onClick={() => logout({ returnTo: window.location.origin })}
          >
            {user?.name?.charAt(0)?.toUpperCase()}
          </Avatar>
        </div>
      </div>
    </>
  );
};
export default HeaderBar;
