import { combineReducers } from "redux";
import Auth from "./authReducer";
import Navigation from "./navigationReducer";
import FilterReducer from "./filterReducer";
import UserReducer from "./userReducer";

const reducer = combineReducers({
  Auth,
  Navigation,
  FilterReducer,
  UserReducer,
});
export default reducer;
